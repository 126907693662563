import React from 'react';
import styled from 'styled-components';
import CenterContainer from '../textContainer';
import { Colors } from '../../constants';

interface SubTextProps {
	text: string;
}

const StyledSubText = styled.span`
	font-size: 3vmin;
	color: ${() => Colors.Primary};
	margin: 0;
	text-align: center;
`;

const SubText = ({ text }: SubTextProps) => {
	return (
		<CenterContainer>
			<StyledSubText>{text}</StyledSubText>
		</CenterContainer>
	);
};

export default SubText;
