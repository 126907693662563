import React from 'react';
import styled from 'styled-components';
import CenterContainer from '../textContainer';
import { Colors } from '../../constants';

interface HeroTextProps {
	text: string;
}

const StyledTitle = styled.h1`
	font-size: 10vmin;
	color: ${() => Colors.Primary};
	margin: 0;
	text-align: center;
`;

const HeroText = ({ text }: HeroTextProps) => {
	return (
		<CenterContainer>
			<StyledTitle>{text}</StyledTitle>
		</CenterContainer>
	);
};

export default HeroText;
