import React from 'react';
import styled from 'styled-components';

interface CenterContainerProps {
	children: React.ReactNode;
}

const TextContainer = styled.div`
	justify-content: center;
	align-items: center;
	display: flex;
`;

const CenterContainer = ({ children }: CenterContainerProps) => {
	return <TextContainer>{children}</TextContainer>;
};

export default CenterContainer;
