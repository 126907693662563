import React, { useEffect } from 'react';
import styled from 'styled-components';
import HeroText from './components/heroText';
import { Colors, GITHUB_URL, LINKEDIN_URL, SITE_NAME } from './constants';
import SubText from './components/subText/subText';
import LinkRow from './components/linkRow';

const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background: ${() => Colors.White};
	overflow: hidden;
	flex-direction: column;
	position: fixed;
`;

function Main() {
	useEffect(() => {
		document.title = SITE_NAME;
	}, []);

	return (
		<MainContainer>
			<HeroText text={SITE_NAME} />
			<SubText text="Software Engineer" />
			<LinkRow githubLink={GITHUB_URL} linkedinLink={LINKEDIN_URL} />
		</MainContainer>
	);
}

export default Main;
