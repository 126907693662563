import React from 'react';
import styled from 'styled-components';
import GitHubIcon from '../icons/github';
import LinkedInIcon from '../icons/linkedin';

interface LinkRowProps {
	githubLink: string;
	linkedinLink: string;
}

const StyledContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	padding: 2vmin 0;
	width: 100%;
`;

const StyledButton = styled.button`
	background: none;
	border: none;
	margin: 0 0.5vmin;
	cursor: pointer;
`;

const LinkRow = ({ githubLink, linkedinLink }: LinkRowProps) => {
	return (
		<StyledContainer>
			<a href={githubLink} target="_blank" rel="noreferrer">
				<StyledButton>
					<GitHubIcon />
				</StyledButton>
			</a>
			<a href={linkedinLink} target="_blank" rel="noreferrer">
				<StyledButton>
					<LinkedInIcon />
				</StyledButton>
			</a>
		</StyledContainer>
	);
};

export default LinkRow;
