import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../constants';

interface SvgIconBaseProps {
	width?: number;
	height?: number;
}

const SvgIcon = styled.svg<{ focuscolor: string }>`
	&:hover {
		fill: ${(props) => props.focuscolor};
	}
`;

const SvgIconBase = ({
	width,
	height,
	children,
}: React.PropsWithChildren<SvgIconBaseProps>) => {
	return (
		<SvgIcon
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={width ?? `5vmin`}
			height={height ?? `5vmin`}
			fill={Colors.Primary}
			focuscolor={Colors.Secondary}
		>
			{children}
		</SvgIcon>
	);
};

export default SvgIconBase;
